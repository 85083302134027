import React, { Component } from 'react';

import ReactPullToRefresh from 'react-pull-to-refresh';
import axios from 'axios';

import './location.scss';
import './pullToRefresh/pullToRefresh.scss';

let dev = false;
// if (window.location.hostname === 'localhost') {
// 	dev = true;
// }

let params = {
	weather: {
		sun: {
			inWords: {
				de: 'Die verdammte <b>Liebe</b> ist die Luft',
				en: 'Fucking love is the air',
			},
			details: 'Es ist sonnig, für Alle die es nicht mitbekommen haben.',
		},
		rain: {
			inWords: {
				de: 'Es regnet jetzt <b>verdammt</b> nochmal.',
				en: "It's fucking <b>raining</b> now.",
			},
			details:
				'Du kannst nach draußen schauen, um mehr Informationen zu erhalten.',
		},
		801: {
			inWords: {
				de: 'Ein paar <b>Wolken</b> am Himmel.',
			},
		},
		clouds: {
			inWords: {
				de: 'Einfach <b>verdammt</b> grau.',
				en: "It's fucking <b>clouds</b> now.",
			},
			details: 'Wolken überall. Aber es ist warm.',
		},
		clear: {
			inWords: {
				de: 'Der Himmel ist ja mal <b>mega</b> klar.',
				en: "It's fucking <b>clouds</b> now.",
			},
		},
		snow: {
			inWords: {
				de: 'Eiskalt - wie im verdammten KÜhlschrank.',
				en: 'Freezing cold like a fucking fridge',
			},
			details: 'Keine Beleidigung für deinen Kühlschrank.',
		},
	},
};

class Location extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		if (dev) {
			this.state = {
				city: 'Leipzig',
				current: {
					dt: 1679905588,
					sunrise: 1679893095,
					sunset: 1679938415,
					temp: 4.16,
					feels_like: -0.82,
					pressure: 1013,
					humidity: 66,
					dew_point: -1.43,
					uvi: 1.29,
					clouds: 0,
					visibility: 10000,
					wind_speed: 7.72,
					wind_deg: 310,
					weather: [
						{
							id: 801,
							main: 'Clouds',
							description: 'Ein paar Wolken',
							icon: '02d',
						},
					],
				},
				state: 'clear',
				icon: './img/icons/01d.png',
				inWords: 'Ein paar <b>Wolken</b> am Himmel.',
				sun: {
					sunrise: 1679893095,
					sunset: 1679938415,
				},
				daily: [
					{
						dt: 1679914800,
						sunrise: 1679893095,
						sunset: 1679938415,
						moonrise: 1679901600,
						moonset: 1679875200,
						moon_phase: 0.2,
						temp: {
							day: 5.12,
							min: 1.28,
							max: 5.91,
							night: 1.28,
							eve: 3.77,
							morn: 2.73,
						},
						feels_like: {
							day: 0.61,
							night: -3.74,
							eve: -0.77,
							morn: -1.47,
						},
						pressure: 1014,
						humidity: 59,
						dew_point: -1.97,
						wind_speed: 8.46,
						wind_deg: 315,
						wind_gust: 12.2,
						weather: [
							{
								id: 616,
								main: 'Snow',
								description: 'Regen und Schnee',
								icon: '13d',
							},
						],
						clouds: 30,
						pop: 1,
						rain: 4.12,
						snow: 0.33,
						uvi: 2.86,
					},
					{
						dt: 1680001200,
						sunrise: 1679979358,
						sunset: 1680024914,
						moonrise: 1679990400,
						moonset: 1679965740,
						moon_phase: 0.23,
						temp: {
							day: 6.31,
							min: -0.21,
							max: 6.95,
							night: 2.84,
							eve: 4.97,
							morn: -0.21,
						},
						feels_like: {
							day: 2.16,
							night: 0.27,
							eve: 3.4,
							morn: -5.5,
						},
						pressure: 1025,
						humidity: 49,
						dew_point: -3.69,
						wind_speed: 8.06,
						wind_deg: 281,
						wind_gust: 14.21,
						weather: [
							{
								id: 616,
								main: 'Snow',
								description: 'Regen und Schnee',
								icon: '13d',
							},
						],
						clouds: 42,
						pop: 0.67,
						rain: 0.36,
						snow: 0.11,
						uvi: 2.31,
					},
					{
						dt: 1680087600,
						sunrise: 1680065621,
						sunset: 1680111413,
						moonrise: 1680079920,
						moonset: 1680055500,
						moon_phase: 0.25,
						temp: {
							day: 6.22,
							min: 2.75,
							max: 10.24,
							night: 7.51,
							eve: 9.21,
							morn: 2.92,
						},
						feels_like: {
							day: 3.12,
							night: 4.71,
							eve: 7.52,
							morn: -1.01,
						},
						pressure: 1017,
						humidity: 83,
						dew_point: 3.6,
						wind_speed: 5.49,
						wind_deg: 205,
						wind_gust: 10.53,
						weather: [
							{
								id: 804,
								main: 'Clouds',
								description: 'Bedeckt',
								icon: '04d',
							},
						],
						clouds: 100,
						pop: 0.23,
						uvi: 2.95,
					},
					{
						dt: 1680174000,
						sunrise: 1680151884,
						sunset: 1680197912,
						moonrise: 1680170040,
						moonset: 1680144540,
						moon_phase: 0.29,
						temp: {
							day: 14.5,
							min: 7.28,
							max: 14.5,
							night: 9.3,
							eve: 11.34,
							morn: 9.92,
						},
						feels_like: {
							day: 13.91,
							night: 6.68,
							eve: 10.75,
							morn: 7,
						},
						pressure: 1008,
						humidity: 73,
						dew_point: 9.73,
						wind_speed: 7.96,
						wind_deg: 238,
						wind_gust: 16.35,
						weather: [
							{
								id: 501,
								main: 'Rain',
								description: 'Mäßiger Regen',
								icon: '10d',
							},
						],
						clouds: 99,
						pop: 1,
						rain: 6.36,
						uvi: 2.91,
					},
					{
						dt: 1680260400,
						sunrise: 1680238148,
						sunset: 1680284411,
						moonrise: 1680260640,
						moonset: 1680232860,
						moon_phase: 0.32,
						temp: {
							day: 12.55,
							min: 8.08,
							max: 12.55,
							night: 10.48,
							eve: 10.96,
							morn: 9.4,
						},
						feels_like: {
							day: 11.9,
							night: 9.65,
							eve: 10.3,
							morn: 6.8,
						},
						pressure: 1001,
						humidity: 78,
						dew_point: 8.8,
						wind_speed: 10.78,
						wind_deg: 243,
						wind_gust: 19.07,
						weather: [
							{
								id: 500,
								main: 'Rain',
								description: 'Leichter Regen',
								icon: '10d',
							},
						],
						clouds: 100,
						pop: 1,
						rain: 2.85,
						uvi: 1.9,
					},
					{
						dt: 1680346800,
						sunrise: 1680324412,
						sunset: 1680370911,
						moonrise: 1680351360,
						moonset: 1680320640,
						moon_phase: 0.35,
						temp: {
							day: 7.67,
							min: 6.37,
							max: 9.4,
							night: 6.59,
							eve: 6.37,
							morn: 8.77,
						},
						feels_like: {
							day: 3.12,
							night: 1.87,
							eve: 1.3,
							morn: 4.64,
						},
						pressure: 998,
						humidity: 85,
						dew_point: 5.27,
						wind_speed: 10.96,
						wind_deg: 246,
						wind_gust: 18.97,
						weather: [
							{
								id: 501,
								main: 'Rain',
								description: 'Mäßiger Regen',
								icon: '10d',
							},
						],
						clouds: 100,
						pop: 1,
						rain: 9.02,
						uvi: 2,
					},
					{
						dt: 1680433200,
						sunrise: 1680410676,
						sunset: 1680457410,
						moonrise: 1680442140,
						moonset: 1680408120,
						moon_phase: 0.38,
						temp: {
							day: 1.77,
							min: 1.34,
							max: 4.2,
							night: 1.34,
							eve: 2.27,
							morn: 1.6,
						},
						feels_like: {
							day: -3.05,
							night: -3.46,
							eve: -2.26,
							morn: -3.79,
						},
						pressure: 1009,
						humidity: 98,
						dew_point: 1.48,
						wind_speed: 7.12,
						wind_deg: 291,
						wind_gust: 13.16,
						weather: [
							{
								id: 616,
								main: 'Snow',
								description: 'Regen und Schnee',
								icon: '13d',
							},
						],
						clouds: 100,
						pop: 1,
						rain: 15.38,
						snow: 0.65,
						uvi: 2,
					},
					{
						dt: 1680519600,
						sunrise: 1680496940,
						sunset: 1680543909,
						moonrise: 1680532860,
						moonset: 1680495360,
						moon_phase: 0.41,
						temp: {
							day: 3.97,
							min: -0.35,
							max: 4.15,
							night: -0.35,
							eve: 1.75,
							morn: 0.21,
						},
						feels_like: {
							day: 0.65,
							night: -5.03,
							eve: -2,
							morn: -3.87,
						},
						pressure: 1025,
						humidity: 65,
						dew_point: -2.1,
						wind_speed: 5.27,
						wind_deg: 341,
						wind_gust: 10.47,
						weather: [
							{
								id: 600,
								main: 'Snow',
								description: 'Mäßiger Schnee',
								icon: '13d',
							},
						],
						clouds: 100,
						pop: 0.96,
						snow: 2.08,
						uvi: 2,
					},
				],
			};
		}

		this.locationHandler = this.locationHandler.bind(this);
		this.handleRefresh = this.handleRefresh.bind(this);
	}

	componentDidMount() {
		let $this = this;

		$this.locationHandler();

		setInterval(() => {
			$this.locationHandler();
		}, 60000 * 5);
	}

	locationHandler() {
		let $this = this;

		// if ( dev) {
		// 	let res = {"loc":"51.3717,12.3321","region":"Saxony","city":"Leipzig","postal":"04159"}
		// 	this.weatherHandler(res.loc, res)
		// } else {
		// 	axios.get(`./loca/`)
		// 	.then(res => {
		// 		console.log(res.data);

		// 		this.weatherHandler(res.data.loc,res.data)
		// 	})
		// }

		function success(position) {
			const latitude = position.coords.latitude;
			const longitude = position.coords.longitude;

			console.log(latitude, longitude);

			let latLong = {
				lat: latitude,
				long: longitude,
			};

			$this.weatherHandler(latLong);
		}

		function error() {
			alert('Unable to retrieve your location');
		}

		navigator.geolocation.getCurrentPosition(success, error);
	}

	weatherHandler(latLong, res) {
		let $this = this;

		if (latLong === undefined) {
			return false;
		}

		axios
			.get(
				`https://api.openweathermap.org/geo/1.0/reverse?lat=${latLong['lat']}&lon=${latLong['long']}&limit=1&appid=226b0b119320d2a49978c8c3bdf7a06d`
			)
			.then((res) => {
				$this.setState({
					city: res.data[0].name,
				});
			});

		axios
			.get(
				`https://api.openweathermap.org/data/3.0/onecall?lat=${latLong['lat']}&lon=${latLong['long']}&appid=226b0b119320d2a49978c8c3bdf7a06d`
			)
			.then((res) => {
				console.log(res.data);

				console.log(res.data.current.weather[0].main.toLowerCase());

				let the_inWords =
					params['weather'][
						res.data.current.weather[0].main.toLowerCase()
					]['inWords']['de'];

				if (
					res.data.current.weather[0].id !== undefined &&
					params['weather'][res.data.current.weather[0].id] !==
						undefined
				) {
					the_inWords =
						params['weather'][res.data.current.weather[0].id][
							'inWords'
						]['de'];
				}

				$this.setState({
					current: res.data.current,
					daily: res.data.daily,
					state: res.data.current.weather[0].main.toLowerCase(),
					icon:
						'./img/icons/' +
						res.data.current.weather[0].icon +
						'.png',
					inWords: the_inWords,
					sun: {
						sunrise: res.data.current.sunrise,
						sunset: res.data.current.sunset,
					},
				});
			});
	}

	sun() {
		let { sun, daily } = this.state;

		let icon = null;

		let sunEl = null;
		switch (true) {
			case Date.now() < sun.sunrise * 1000:
				sunEl = sun.sunrise;
				icon = './img/icons/sunrise.svg';
				break;
			case Date.now() >= sun.sunrise * 1000 &&
				Date.now() < sun.sunset * 1000:
				sunEl = sun.sunset;
				icon = './img/icons/sunset.svg';
				break;
			case new Date().setHours(new Date().getHours() + 2) >
				sun.sunset * 1000:
				if (daily[1] !== undefined) {
					sunEl = daily[1].sunrise;
					icon = './img/icons/sunrise.svg';
				}
				break;
			default:
				break;
		}

		return (
			<span className="sun-info">
				<img src={icon} className="sun-icon" alt="Sonne" />{' '}
				{new Date(sunEl * 1000)
					.toLocaleTimeString('de-DE')
					.slice(0, -3)}
			</span>
		);
	}

	handleRefresh() {
		this.locationHandler();
	}

	render() {
		if (this.state.current === undefined) {
			return false;
		}

		return (
			<ReactPullToRefresh
				onRefresh={this.handleRefresh}
				icon={
					<i className="material-symbols-outlined">arrow_downward</i>
				}>
				<div className="output">
					<div className="loca-details">
						{this.sun()} <span>{this.state.city}</span>
					</div>
					<div className="icon">
						<img src={this.state.icon} alt="Wetter" />
					</div>

					<div>
						<div
							className={`in-words ${this.state.state}`}
							dangerouslySetInnerHTML={{
								__html: this.state.inWords,
							}}></div>

						<div className="quick-info">
							<div className="temp">
								{this.state.current.temp.toFixed()} °C
							</div>
						</div>

						<div className="details">
							Du kannst nach draußen schauen, um mehr
							Informationen zu erhalten.
						</div>

						<nav className="legal">
							<div>APP-Version _v1.5</div>
							<a
								href="https://philippunger.com/rechtlich/impressum.php"
								rel="noreferrer"
								target="_blank">
								Impressum
							</a>
							<a
								href="https://philippunger.com/rechtlich/datenschutz.php"
								rel="noreferrer"
								target="_blank">
								Datenschutz
							</a>
						</nav>
					</div>
				</div>
			</ReactPullToRefresh>
		);
	}
}

export default Location;
